import React, { FC, useContext, useEffect, useState } from 'react';
import { SearchContext } from 'utils/context/searchContextProvider';
import Button from 'common/Button';
import ScreenRecognitionContext from 'utils/context/screenRecognitionContext';
import { isBrowser } from 'utils/browser';
import { SearchResultsProps } from './models';
import './SearchResults.scss';
import SearchResultsItem from './SearchResultsItem';

const SearchResults: FC<SearchResultsProps> = ({
  resultsText,
  noResultsText,
  lang,
  loadMoreText,
  readMoreText: readMore,
}) => {
  const { query, algoliaClient } = useContext(SearchContext);
  const screenRecognitionContext = useContext(ScreenRecognitionContext);
  const linkQuery = isBrowser() ? new URLSearchParams(window.location.search).get('q') : null;
  const [hits, setHits] = useState([]);
  const counter = screenRecognitionContext.isMobile ? 6 : 10;
  const [resultsCount, setResultsCount] = useState(counter);
  const [headerText, setHeaderText] = useState('');
  const searchText = linkQuery || query;
  const index = algoliaClient.initIndex(process.env.GATSBY_ALGOLIA_INDEX!);

  useEffect(() => {
    if (searchText) {
      setResultsCount(counter);
      index
        .search(searchText, {
          filters: `lang:"${lang}"`,
        })
        .then((foundItems) => {
          setHits(foundItems.hits);
        });
    } else {
      setHits([]);
    }
  }, [searchText, counter, lang]);

  useEffect(() => {
    if (hits.length) {
      setHeaderText(
        resultsText.replace('{searchText}', searchText).replace('{number}', hits.length.toString())
      );
    } else {
      setHeaderText(noResultsText.replace('{searchText}', searchText));
    }
  }, [hits, searchText, noResultsText, resultsText]);

  return (
    <section className="search-results" data-testid="search results">
      <h1 className="search-results__header">{headerText}</h1>
      <ul className="search-results__list">
        {hits
          .slice(0, resultsCount)
          .map(({ _snippetResult: { content: { value: content } }, url, title }) => (
            <SearchResultsItem key={`${title} - ${url}`} {...{ content, title, url, readMore }} />
          ))}
      </ul>
      {hits.length > resultsCount ? (
        <div className="search-results__btn">
          <Button ariaLabel={loadMoreText} onClick={() => setResultsCount(resultsCount + counter)}>
            {loadMoreText}
          </Button>
        </div>
      ) : null}
    </section>
  );
};

export default SearchResults;
