import React, { FC } from 'react';
import { Link } from 'gatsby';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { SearchResultsItemProps } from './models';

const SearchResultsItem: FC<SearchResultsItemProps> = ({ title, content, url, readMore }) => (
  <li className="search-results__element" data-testid="search results item">
    <Link className="search-results__title" aria-label={title} to={url}>
      <DangerouslySetInnerHtml html={title} element="span" />
    </Link>
    <div className="search-results__description">
      <DangerouslySetInnerHtml html={content} element="span" />
      {readMore ? (
        <p className="search-results__more">
          <Link aria-label={title} to={url}>
            {readMore}
            {`>>`}
          </Link>
        </p>
      ) : null}
    </div>
  </li>
);

export default SearchResultsItem;
