import React, { FC } from 'react';
import './SearchResultsPage.scss';
import { graphql } from 'gatsby';
import SearchResults from 'components/SearchResults';
import Layout from 'layout/Layout';

const SearchResultsPage: FC<SearchResultsTypes.SearchResultsProps> = ({
  data: {
    allUmbracoSearchResults: { nodes: umbracoSearchResults },
  },
}) => {
  const {
    langProps: { urls, lang },
    resultsText,
    noResultsText,
    loadMoreText,
    readMoreText,
  } = umbracoSearchResults[0];

  return (
    <Layout langProps={{ urls, lang }}>
      <SearchResults {...{ resultsText, noResultsText, loadMoreText, readMoreText, lang }} />
    </Layout>
  );
};

export const query = graphql`
  query($pageId: String!) {
    allUmbracoSearchResults(filter: { id: { eq: $pageId } }) {
      nodes {
        resultsText
        noResultsText
        loadMoreText
        readMoreText
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
      }
    }
  }
`;

export default SearchResultsPage;
